import V from 'voUtils/V.js';

import {
	isBlank,
	objectIsSimilar
}
from 'voUtils/tools.js';

import {
	fireSearch
}
from 'voUtils/TagEx.js';

import {
	doReq
}
from 'voUtils/RestEx.js';

import {
	getVoucher
}
from 'voUtils/VoucherStore.js';
import {
	getAbTest
}
from 'voUtils/AbTest.js';

import {
	getFavIds
}
from 'voUtils/FavStore.js';

import {
	findResultFields
}
from 'voUtils/BrowserEx.js';

import DateEx from 'voUtils/DateEx.js';



const MAX_SEARCH_RESULTS = (VOFFICE.searchOptions && VOFFICE.searchOptions.max) || 18;
const DEFAULT_SEARCH = {
	adults: 2,
	children: 0,
	babys: 0,
	petsCount: 0,
	from: undefined,
	till: undefined,
	name: undefined,
	region: '',
	bedrooms_min: '',
	bathrooms_min: '',
	type: '',
	unitgroupid: ''

};

export default {
	voMsgCodes: [
		'guest.pl',
		'pet.pl'
	],

	data: {
		searchData: Object.assign({}, DEFAULT_SEARCH),
		ignoreDateHint: false,
		ignorePetsHint: false,
		searchFields: {},
		sorting: 'random',
		extended: false,
		searching: false,
		highlightUnit: undefined,
		mapSelectedUnit: {},
		view: 'list',
		forceMap: false,
		units: [],
		mapUnits: [],
		broaderSearchResults: undefined,
		ids: [],
		resultCount: 0,
		resultCount1: 0,
		resultCount2: 0,
		facilityResults: [],
		currentPage: 0,
		pageCount: 0,
		lastSearch: undefined,
		error: undefined,
		showSidebar: false,
		headerImgClass: '',
		showAdvancedFilters: false,
		isFlexSearch: false,
		imagesForModal: [],
		openstreetmap: false
	},
	beforeMount: function () {

		this.checkOsmConsent();

		var results = this.$el.getElementsByClassName('results');
		if (results.length) {
			this.resultFields = findResultFields(results[0].innerHTML, this.$el);
		}

		var addSearchFieldByOptionList = (id) => {
			this.searchFields[id] = {
				options: {}
			};
			let elements = this.$el.querySelectorAll('[data-' + id + '-id]');
			for (let el of elements) {
				this.searchFields[id].options[el.getAttribute('data-' + id + '-id')] = el.textContent;
			}
		};

		addSearchFieldByOptionList('sorting');
		addSearchFieldByOptionList('region');
		addSearchFieldByOptionList('lage');

		var addSearchFieldLabel = (id, label) => {
			this.searchFields[id] = {
				label: label
			};
		};

		addSearchFieldLabel('favorites', this.voMsg('favorites'));
		addSearchFieldLabel('ids', this.voMsg('yourchoice'));
		addSearchFieldLabel('offers', this.voMsg('specialoffers'));
		addSearchFieldLabel('offer', this.voMsg('specialoffer'));

		var searchPanel = document.getElementById('searchpanel');
		if (searchPanel) {
			searchPanel.querySelectorAll('[v-model]').forEach((el) => {

				let field = el.getAttribute('v-model');
				let label;
				let fields = this.searchFields;

				if (field.startsWith('searchData.')) {
					field = field.substring(11);

					label = el.getAttribute('data-label');

					if (!label && el.getAttribute('id')) {
						let labelFor = searchPanel.querySelector("label[for='" + el.getAttribute('id') + "']");
						if (labelFor) {
							label = labelFor.textContent;
						}
					}

					if (!label) {
						let placeholder = el.querySelector('[placeholder]');
						if (placeholder) {
							label = placeholder.getAttribute('placeholder');
						}
					}

					if (!label) {
						let disabled = el.querySelector('[disabled][selected]');
						if (disabled) {
							label = disabled.textContent;
						}
					}

					if (label) {
						fields[field] = {
							label: label
						};

						if (el.tagName == 'SELECT') {
							fields[field].options = {};
							el.querySelectorAll('option').forEach((o) => {
								let val = o.getAttribute('value');
								if (val) {
									fields[field].options[val] = o.textContent;
								}
							});
						}


					} else {
						V.logWarn("no label found for", field, "use <label for=''>, placeholder, a disabled option or attribute 'data-label'");
					}

				}


			});

		}

		//V.log("this.searchFields", this.searchFields);


		this.setHeaderImageByHash();
		window.addEventListener('hashchange', (e) => {
			//console.log('The hash has changed!', e);
			this.updateSearchDataByUrl();
			this.goSearch();
			this.setHeaderImageByHash();
		}, false);


		//console.log('search mueritz before mount.............................');
		this.updateSearchDataByUrl('unitgroupid');
		if (this.resultFields) {
			this.goSearch(false, 'unitgroupid');
		}


	},
	mounted: function () {
		window.addEventListener('ccm19WidgetClosed', (event) => {
			this.checkOsmConsent();
		});
		//console.log('mounted@region:::' + this.searchData.region);
		let id = this.searchData.region;
		if (id && id.indexOf('/') > 0) {
			id = id.substring(0, id.indexOf('/'));
		}
		if (id == 'ostsee') {
			$("#all-regions").hide();
			$("#ostsee-regions").show();
			$("#muritz-regions").hide();
		} else if (id == 'mueritz') {
			$("#all-regions").hide();
			$("#ostsee-regions").hide();
			$("#muritz-regions").show();
		} else {
			$("#all-regions").show();
			$("#ostsee-regions").hide();
			$("#muritz-regions").hide();
		}

	},
	computed: {
		mapItems: function () {
			return this.view == 'map' ? this.mapUnits : this.units;
		},
		periodLabel: function () {
			let label = '';
			if (this.searchData.from) {
				label = this.voFormatDate(this.searchData.from, 'withDay');
			}
			if (this.searchData.till) {
				label += ' - ' + this.voFormatDate(this.searchData.till, 'withDay');
			}

			return label;
		},
		guestLabel: function () {
			var sd = this.searchData;
			var amount = ((sd.adults || 0) - 0) + ((sd.children || 0) - 0) + ((sd.babys || 0) - 0);
			var label = amount + ' ' + this.voMsgPl('guest.pl', amount);
			if (sd.petsCount) {
				label += ', ' + sd.petsCount + ' ' + this.voMsgPl('pet.pl', sd.petsCount);

			}
			return label;
		},

		regionParentLabel: function () {
			let regionId = this.searchData.region;
			if (regionId) {
				if (regionId.indexOf('/') > 0) {
					regionId = regionId.substring(0, regionId.indexOf('/'));
				}
				return this.searchFields.region.options[regionId];
			} else {
				return 'Alle Region';
			}

		},
		regionLabel: function () {
			let regionId = this.searchData.region;
			if (regionId.indexOf('/') > 0) {
				return this.searchFields.region.options[this.searchData.region];
			} else {
				return 'Alle Orte';
			}
		},
		sortingLabel: function () {
			return this.searchFields.sorting.options[this.sorting || 'random'];
		},
		searchUrl: function () {
			var sd = this.searchData;

			//var url = 's#';
			var url = 'ostsee/fischland-darss-zingst-urlaub#';
			if (sd.region) {
				url += '/' + sd.region;
			}

			var searchKeys = Object.keys(sd).sort();
			var params = [];
			for (let sp of searchKeys) {
				if (!isBlank(sd[sp]) && sp != 'region') {
					params.push(sp + '=' + sd[sp]);
				}
			}

			if (this.sorting && this.sorting != 'random') {
				params.push('sort=' + this.sorting);
			}

			if (this.view && this.view != 'list') {
				params.push('view=' + this.view);
			}

			if (params.length) {
				url += '?' + params.join('&');
			}
			return url;
		},
		canGoMore: function () {
			return !this.searching && this.ids.length > (this.currentPage + 1) * MAX_SEARCH_RESULTS;
		},
		mailto: function () {
			return `mailto:${this.emailAddress}`;
		},
		emailAddress: function () {
			return (VOFFICE.contactEmail || ['info', 'test.de']).join('@');
		},

		phoneView: function () {
			if (VOFFICE.phoneView) {
				return VOFFICE.phoneView;
			}
		}
	},
	updated: function () {
		$('[data-bs-toggle="tooltip"]').tooltip();
		$('[data-bs-toggle="popover"]').popover();

		var popovers = document.querySelectorAll('.popover');

		// Remove the 'show' class from each popover element
		popovers.forEach(function (popover) {
			popover.remove();
		});

	},
	methods: {

		getNights: function (from, till) {
			//console.log('from', from, 'till', till);
			if (from && till) {
				let diffDays = DateEx.getDays(till, from);
				var nights;
				if (diffDays > 1) {
					nights = "(" + diffDays + ' ' + this.voMsg('tpl.text.nights') + ")";
				} else {
					nights = "(" + diffDays + ' ' + this.voMsg('tpl.text.night') + ")";
				}
				return nights;

			}
		},

		phoneLink: function (phonelink) {
			var el = document.getElementById('preloader');
			var phonenumber;
			if (phonelink) {
				phonenumber = phonelink;
			} else if (VOFFICE.phoneLink) {
				phonenumber = VOFFICE.phoneLink;
			}
			if (phonenumber) {
				window.location.href = phonenumber;
				if (el) {
					el.style.display = "none";
				}
			}
		},

		checkOsmConsent: function () {
			const suffix = VOFFICE.token.substr(VOFFICE.token.length - 12);
			var cookieName = 'allowOpenstreetmap_' + suffix;
			const allowOpenstreet = this.getCookie(cookieName);
			if (allowOpenstreet) {
				this.openstreetmap = true;
			} else {
				const data = CCM.acceptedEmbeddings;
				const targetName = "OpenStreetMap";
				const entry = data.find(item => item.name === targetName);


				if (entry) {
					this.openstreetmap = true;
				} else {
					this.openstreetmap = false;
				}
			}
		},


		getCookie: function (name) {
			const nameEQ = name + "=";
			const ca = document.cookie.split(';');
			for (let i = 0; i < ca.length; i++) {
				let c = ca[i];
				while (c.charAt(0) === ' ') c = c.substring(1, c.length);
				if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
			}
			return null;
		},
		setCookie: function (name, value, days) {
			const date = new Date();
			date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
			const expires = "expires=" + date.toUTCString();
			document.cookie = name + "=" + value + ";" + expires + ";path=/";
		},

		allowOpenstreetmap: function () {
			const suffix = VOFFICE.token.substr(VOFFICE.token.length - 12);
			var cookieName = 'allowOpenstreetmap_' + suffix;
			this.setCookie(cookieName, true, VOFFICE.settings.osmCookieDays);
			this.openstreetmap = true;
		},

		showOpenstreetmap: function () {
			this.openstreetmap = true;
		},

		splitUnits: function (data) {
			this.units1 = [];
			this.units2 = [];
			this.resultCount1 = 0;
			this.resultCount2 = 0;

			if (data.length > 0) {
				const unitsWithAlternatives = [];
				const unitsWithoutAlternatives = [];

				data.forEach(unit => {
					if (unit.alternatives && !unit.foundExactMatch) {
						unitsWithAlternatives.push(unit);
					} else {
						unitsWithoutAlternatives.push(unit);
					}
				});

				if (unitsWithAlternatives.length > 0) {
					this.units2 = unitsWithAlternatives;
					this.resultCount2 = unitsWithAlternatives.length;
				}
				if (unitsWithoutAlternatives.length > 0) {
					this.units1 = unitsWithoutAlternatives;
					this.resultCount1 = unitsWithoutAlternatives.length;
				}
			}


		},
		allowOpenstreetmap: function () {
			const suffix = VOFFICE.token.substr(VOFFICE.token.length - 12);
			var cookieName = 'allowOpenstreetmap_' + suffix;
			this.setCookie(cookieName);
			this.openstreetmap = true;
		},

		showOpenstreetmap: function () {
			this.openstreetmap = true;
		},

		showMap: function (id) {
			let elImage = document.getElementById('image_' + id);
			let elMap = document.getElementById('map_' + id);
			if (elImage && elMap) {
				elImage.style.display = "none";
				elMap.style.display = "block";
			}
		},
		closeUnitMap: function (id) {
			let elImage = document.getElementById('image_' + id);
			let elMap = document.getElementById('map_' + id);
			let elLastviewedUnits = document.getElementById('lastviewed-units');
			if (elImage && elMap) {
				elImage.style.display = "block";
				elMap.style.display = "none";
			}
		},

		scrollIntoView: function (id) {
			let el = document.getElementById(id);
			if (el) {
				el.scrollIntoView({
					behavior: 'smooth',
					block: 'start'
				});
			}
		},
		showImagesModal: function (images, uniqueId = '') {
			if (images && images.length > 1) {
				this.imagesForModal = images;
				//$('#unitImages').modal('show');
				this.showCustomModal('unitImages' + uniqueId);

			} else {
				this.imagesForModal = [];
			}
		},
		showCustomModal: function (id) {
			let wh = window.innerHeight;

			// Get the modal
			const modal = document.getElementById(id);

			if (modal) {
				var dialog = modal.getElementsByClassName("modal-custom-dialog")[0];
				var content = modal.getElementsByClassName("modal-custom-content")[0];
				var body = document.body;

				if (dialog && content) {
					dialog.style.height = wh - 40 + "px";
					content.style.height = wh - 120 + "px";

					modal.style.display = "block";
					body.classList.add('modal-open');


					// When the user clicks anywhere outside of the modal, close it
					window.onclick = function (event) {
						if (event.target == modal) {
							modal.style.display = "none";
							body.classList.remove('modal-open');
						}
					};
				}
			}
		},

		closeCustomModal: function (id) {
			const modal = document.getElementById(id);
			var body = document.body;
			if (modal) {
				modal.style.display = "none";
				body.classList.remove('modal-open');
			}

		},
		showOffers: function (offers) {
			var result = false;
			if (offers) {

				for (var i = 0; i < offers.length; i++) {
					var offer = offers[i];
					if (!offer.voucherCode || offer.voucherCode === this.getVoucherCode()) {
						result = true;
						break;
					}
				}
			}

			return result;

		},
		showOfferInformation: function () {
			var offerId = this.searchData.offerid ? this.searchData.offerid : null;
			var result = false;

			this.offerName = null;
			this.offerMinStay = null;
			this.offerFromdate = null;
			this.offerTilldate = null;
			this.offerDiscountType = null;
			this.offerValue = null;
			this.offerTimeSpan = null;
			this.offerType = null;

			if (offerId) {
				var units = this.units;

				for (var i = 0; i < units.length; i++) {
					var unit = units[i];

					this.dates = [];

					for (var i2 = 0; i2 < unit.offers.length; i2++) {
						var offer = unit.offers[i2];

						if (offer.id == offerId) {
							var offerDateRange = {
								from: '',
								till: ''
							};
							if (offer.name) {
								this.offerName = offer.name;
							}
							if (offer.type) {
								this.offerType = offer.type;
							}
							if (offer.discountType) {
								this.offerDiscountType = offer.discountType;
							}
							if (offer.minStay) {
								this.offerMinStay = offer.minStay;
							}
							if (offer.timeSpan) {
								this.offerTimeSpan = offer.timeSpan;
							}
							if (offer.value) {
								this.offerValue = offer.value;
							}
							if (offer.tilldate) {
								offerDateRange.till = DateEx.formatDate(offer.tilldate, undefined, VOFFICE.mlang);
							}
							if (offer.fromdate) {
								offerDateRange.from = DateEx.formatDate(offer.fromdate, undefined, VOFFICE.mlang);
							}
							this.dates.push(offerDateRange);
						}

						result = true;

					}
				}
			}
			return result;

		},
		getVoucherCode: function () {
			var voucher = getVoucher();
			if (voucher) {
				return voucher.code;
			}
			return null;
		},
		showAdvanced: function () {
			this.showAdvancedFilters = true;
		},
		hideAdvancedFilters: function () {
			this.showAdvancedFilters = false;
		},

		toggleSidebar: function () {
			this.showSidebar = !this.showSidebar;
		},

		setView: function (id) {
			this.view = id || 'list';
			let elLastviewedUnits = document.getElementById('lastviewed-units');
			if (elLastviewedUnits && this.view === "map") {
				elLastviewedUnits.style.display = "none";
			} else {
				elLastviewedUnits.style.display = "block";
			}

			this.updateSearchResults();
		},
		toggleUnitOnMap: function (u) {
			if (this.mapSelectedUnit == u) {
				this.closeMap();
			} else {
				this.forceMap = true;
				this.$nextTick(function () {
					this.mapSelectedUnit = u;
				});
			}
		},
		closeMap: function () {
			this.mapSelectedUnit = {};
			this.forceMap = false;
		},
		hasMoreFacilityResults: function (u) {
			if (u.facility && this.facilityResults[u.facility] && this.facilityResults[u.facility] > VOFFICE.searchOptions.groupFacilities) {
				return true;
			}
		},
		selectRegion: function (id) {
			this.searchData.region = id;
			//console.log('id::' + id);
			if (id == 'ostsee') {
				$("#all-regions").hide();
				$("#ostsee-regions").show();
				$("#muritz-regions").hide();
			} else if (id == 'mueritz') {
				$("#all-regions").hide();
				$("#ostsee-regions").hide();
				$("#muritz-regions").show();
			} else {
				$("#all-regions").show();
				$("#ostsee-regions").hide();
				$("#muritz-regions").hide();
			}
		},
		selectRegionWithParent: function (id, parent) {
			//console.log('parent id::' + id + "::" + parent);
			this.searchData.region = id;
			if (parent == 'ostsee') {
				$("#ostsee-regions").show();
				$("#muritz-regions").hide();
				$("#all-regions").hide();
			} else if (parent == 'mueritz') {
				$("#ostsee-regions").hide();
				$("#all-regions").hide();
				$("#muritz-regions").show();
			}

			if (id == '') {
				this.searchData.region = parent;
			}
		},
		setSorting: function (id, event) {
			this.sorting = id;
		},
		setSearchParamAndRefresh: function (id, val) {
			//this.searchData[id] = val;
			this.$set(this.searchData, id, val);
			this.updateSearchResults();
		},
		resetFilter: function () {
			this.searchData = Object.assign({}, DEFAULT_SEARCH);
			this.searchData.unitgroupid = 58579;
			this.updateSearchResults();
		},
		updateSearchDataByUrl: function (filter = null) {
			this.searchData = Object.assign({}, DEFAULT_SEARCH);

			var req = this.parseUrl(filter);
			this.sorting = req.sorting;
			this.view = req.view || 'list';

			var reg = /^\d+$/;
			for (let k in req.filter) {
				this.$set(this.searchData, k, (reg.test(req.filter[k]) && parseInt(req.filter[k], 10)) || req.filter[k]);
			}

		},
		parseUrl: function (filter = null) {
			var req = {
				fields: this.resultFields,
				filter: {},
				sorting: 'random'
			};

			var hash = window.location.hash;
			console.log('hash::' + hash);
			if (hash) {
				hash = hash.substring(1);

				let url = new URL(hash, 'https://www.v-office.com');
				let params = new URLSearchParams(url.search);

				if (url.pathname.length > 1) {
					req.filter.region = url.pathname.substring(1);
				}


				for (let p of params) {
					if (p[0] == 'view') {
						req.view = p[1];
					} else if (p[0] == 'sort') {
						req.sorting = p[1];
					} else {
						req.filter[p[0]] = p[1];
					}
				}
			}

			if (VOFFICE.searchOptions) {
				Object.assign(req, VOFFICE.searchOptions);
			}

			if (VOFFICE.abtest) {
				req.abtest = VOFFICE.abtest;
			}



			req.filter.unitgroupid = 58579;

			/*if (filter) {
				req.filter[filter] = true;
			}*/
			console.log('req.filter::' + JSON.stringify(req.filter, 4));
			console.log('req.filter.region::' + req.filter.region);
			return req;
		},
		updateSearchResults: function (reload = false) {
			//console.log('updateSearchResults');
			var url = this.searchUrl;

			history.pushState({

			}, "", url);

			this.goSearch(reload);
			//this.scrollIntoView('searchstart');
		},
		getUnitSearchTarget: function (id) {
			return 'unit_' + id;
		},
		getUnitSearchPath: function (path, o) {
			var sd = this.searchData;

			var searchKeys = ['from', 'till', 'adults', 'children', 'babys', 'pets', 'petsCount'];

			var params = [];
			for (let sp of searchKeys) {
				if (o && o[sp]) {
					params.push(sp + '=' + o[sp]);
				} else if (!isBlank(sd[sp])) {
					params.push(sp + '=' + sd[sp]);
				}
			}

			if (params.length) {
				path += '#?' + params.join('&');
			}
			return path;


		},
		getAlternatePeriod: function (u) {
			var result = null;
			if (u.matchingPeriods && u.matchingPeriods.length > 0) {
				result = {
					from: u.matchingPeriods[0].fromdate,
					till: u.matchingPeriods[0].tilldate
				};
				return result;
			}
			if (u.alternatives && u.alternatives.length > 0) {
				result = {
					from: u.alternatives[0].fromdate,
					till: u.alternatives[0].tilldate
				};
				return result;
			}
			return result;
		},
		goSearch: function (reload, filter = null) {

			if (this.searchData.nights_max && this.searchData.nights_max) {
				var diffDays = DateEx.getDays(this.searchData.till, this.searchData.from);
				if (this.searchData.nights_max !== diffDays || this.searchData.nights_min !== diffDays) {
					this.isFlexSearch = true;
				} else {
					this.isFlexSearch = false;
				}
			}

			if (this.lastUrl && this.lastUrl == this.searchUrl && !reload) {
				//V.log("no url change!");
			} else {
				let req = this.parseUrl(filter);
				this.doSearchNow(req);
				window.scrollTo(0, 0);
			}


		},
		showMore: function () {
			this.searching = true;

			var req = Object.assign({}, this.lastSearch);

			var shown = (this.currentPage + 1) * MAX_SEARCH_RESULTS;
			if (this.ids.length > shown) {
				req.ids = this.ids.slice(shown, Math.min(this.ids.length, shown + MAX_SEARCH_RESULTS));
				this.error = undefined;
				doReq('searchUnits', req)
					.then((res) => {
						this.units = this.units.concat(res.units);
						this.splitUnits(this.units);
						this.currentPage += 1;
						this.searching = false;
					})
					.catch(e => {
						this.searching = false;
						this.error = e;
					});


			} else {
				//V.logWarn('no more results');
				this.searching = false;
			}

		},

		setPage: function (page) {
			//V.log("setPage", page);
			page--;
			this.units = [];
			this.searching = true;

			var req = Object.assign({}, this.lastSearch);

			var start = page * MAX_SEARCH_RESULTS;
			req.ids = this.ids.slice(start, Math.min(this.ids.length, start + MAX_SEARCH_RESULTS));
			this.error = undefined;
			doReq('searchUnits', req)
				.then((res) => {
					this.units = res.units;
					this.currentPage = page;
					this.searching = false;

				})
				.catch(e => {
					this.searching = false;
					this.error = e;
				});


		},
		doSearchNow: function (req) {
			this.units = [];
			this.searching = true;

			if (req.filter.favorites) {
				delete req.filter.favorites;
				req.filter.ids = getFavIds();
			}
			if (req.view == 'map') {
				req.mapsearch = true;
			}

			var voucher = getVoucher();
			if (voucher) {
				var code = localStorage.getItem("voucher_code");
				if (code === voucher.code) {
					req.voucherCode = voucher.code;
				}
			}
			if (getAbTest()) {
				req.abtest = getAbTest();
			}


			this.lastUrl = this.searchUrl;
			this.error = undefined;

			doReq('searchUnits', req)
				.then((res) => {
					//V.log("res", res);

					this.lastSearch = req;
					this.searching = false;

					if (req.mapsearch) {

						this.units = [];
						this.ids = [];

						if (VOFFICE.settings && VOFFICE.settings.mapType === 'openstreet') {

							var i = 0;
							var mapItems = [];
							res.units.forEach(function (item) {
								if (item.length === 3) {
									i++;
									mapItems[i] = new Array(item[0], item[1], item[2]);
								} else if (item.length > 3) {
									for (let a = 2; a < item.length; a++) {
										i++;
										mapItems[i] = new Array(item[0], item[1], item[a]);
									}
								}

							});

							this.mapUnits = mapItems;
							if (this.$refs.mysearch) {
								this.$refs.mysearch.update();
							}

						} else {
							this.mapUnits = res.units;
						}


						let i2;
						let unitsCount = 0;
						for (i2 = 0; i2 < res.units.length; i2++) {
							for (let j = 2; j < res.units[i2].length; j++) {
								unitsCount++;

							}

						}
						this.resultCount = unitsCount;

					} else {

						this.units = res.units;
						this.splitUnits(this.units);
						this.broaderSearchResults = res.broaderSearchResults;
						this.ids = res.ids;
						this.facilityResults = res.facilityResults || [];
						this.currentPage = 0;
						this.pageCount = Math.ceil(this.ids.length / MAX_SEARCH_RESULTS);

						this.resultCount = res.total || this.ids.length;

						if (this.$refs.mysearch) {
							this.$refs.mysearch.update();
						}
						if (this.$refs.pagination) {
							this.$refs.pagination.overwriteCurrentPage(1);
						}
					}
					fireSearch(this.resultCount, req);

				})
				.catch(e => {
					this.searching = false;
					this.error = e;
				});
		},
		setHeaderImageByHash() {
			var hash = window.location.hash;
			var imgClass = '';
			//console.log('hash:::', hash);
			if (hash.indexOf('type=FLAT') >= 0) {
				imgClass = 'fewo-banner';
			}
			if (hash.indexOf('type=HOUSE') >= 0) {
				imgClass = 'house-banner';
			}
			if (hash.indexOf('offers=true') >= 0) {
				imgClass = 'offers-banner';
			}
			if (hash.indexOf('favorites=true') >= 0) {
				imgClass = 'favorits-banner';
			}
			this.headerImgClass = imgClass;
		}
	},
	watch: {
		mapSelectedUnit: function (nv) {
			//V.log("mapSelectedUnit", nv);
			if (nv && nv.lazy) {

				var req = {
					fields: this.resultFields,
					filter: this.lastSearch.filter,
					ids: [nv.info[2]]
				};


				doReq('searchUnits', req)
					.then((res) => {
						//this.units = res.units;
						if (res.units && res.units.length) {
							Object.assign(nv, res.units[0]);
							nv.lazy = false;
						}

					});

			}
		}
	}

};

$(document).ready(function () {
	$("#facilities").change(function () {
		self.location.href = './' + $("#facilities").val();
	});
});